import AppServiceNewApi from "./AppServiceNewApi";

class CompanyServiceNewApi extends AppServiceNewApi {
  constructor() {
    super();
    this.path = "/api/internal/v1/companies";
  }

  getCompanies(pagination, filters, hasOrder, orderBy, organizationId) {
    let filterString = "&";
    if (filters?.name) filterString = `${filterString}Name=${filters?.name}&`;
    if (filters?.status)filterString = `${filterString}Status=${filters?.status}&`;
    if (hasOrder)filterString = `${filterString}OrderByName=${hasOrder}&`;
    if (hasOrder && orderBy)filterString = `${filterString}AscOrDesc=${orderBy}&`;
    if (filterString === "&") filterString = "";
    else filterString = filterString.substring(0, filterString?.length - 1);
    const URL = `${this.path}?PageNumber=${pagination?.page}&PageSize=${pagination?.size}${filterString}`;
    //const URL = `${this.path}?organizationId=${organizationId}&PageNumber=${pagination?.page}&PageSize=${pagination?.size}${filterString}`;
    return this.http.get(`${URL}`);
  }

  getCompanyById(id) {
    const URL = `${this.path}/${id}`;
    return this.http.get(`${URL}`);
  }

  addCompany(body) {
    const URL = `${this.path}`;
    return this.http.post(`${URL}`, body);
  }

  updateCompany(body, id) {
    const URL = `${this.path}/${id}`;
    return this.http.put(`${URL}`, body);
  }

  getLogo(id) {
    return this.http.get(`${this.path}/${id}/logo`);
  }

  changeLogoToCompany(image, id) {
    const URL = `${this.path}/${id}`;
    return this.http.post(URL, image);
  }

  resendQuestionnaire(id) {
    return this.http.post(
      `${this.path}/${id}/resend-questionnaire-link-employess`
    );
  }

  me() {
    return this.http.get(`/auth/v1/auth/me`);
  }

  codeGenerateLink(companyId) {
		return this.http.get(`${this.path}/${companyId}/qr-code-link`);
	}

  validateWebhookInformation(body) {
    return this.http.post(`${this.path}/verify-connect-webhook`, body);
  }

  updateEmployerByFile(file){
    return this.http.put(`${this.path}/update-from-file`, file)
  }
  
  getTempleteForUpdateEmployers(){
    return this.http.get(`${this.path}/download-update-company-csv`)
  }
  
}
export default CompanyServiceNewApi;
