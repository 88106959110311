import { theme } from "../theme";

export const EmployeeTableStyles = () => ({
  emptyTableCell: {
    width: "4%",
  },
  button: {
    width: '100%',
    maxHeight: '64px',
    height: '56px',
    fontSize: '1.2rem',
    textTransform: 'none',
    backgroundColor: '#101010',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#101010',
    },
    '& .MuiButton-endIcon>*:nth-of-type(1)': {
      fontSize: '1.6rem',
    },
  },
  checkbox: {
    color: "black",
    "&.MuiCheckbox-root": {
      color: "black",
    },
  },
  tableContainer: {
    minHeight: "700px",
    maxHeight: "800px",
    boxSizing: 'border-box !important',
    "::-webkit-scrollbar": {
      width: "10px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "rgba(16, 16, 16, 0.5)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  tableToolbar: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    padding: "32px 28px",
    borderBottom: "1px solid rgba(224,224,224,1)",
  },
  toolbarCtn: {
    alignItems: "center",
    display: "flex",
    gap: 3,
  },
  filterButton: {
    display: "flex",
    justifyContent: "space-around",
    background: "#FFF",
    boxShadow: "0",
    paddingX: "28px",
    height: "56px",
    border: "1px solid #101010",
    color: "#101010",
    fontSize: "1.2rem",
    textTransform: "none",
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem",
      marginLeft: 5,
    },
    "&:hover": {
      background: "#FFF",
      boxShadow: 3,
    },
  },
  submitButton: {
    display: "flex",
    justifyContent: "space-around",
    background: theme.palette.primary.main,
    boxShadow: "0",
    paddingX: "28px",
    height: "56px",
    border: `1px solid ${theme.palette.primary.main}`,
    color: "#FFF",
    fontSize: "1.2rem",
    textTransform: "none",
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem",
      marginLeft: 5,
    },
    "&:hover": {
      background: theme.palette.primary.main,
      boxShadow: 3,
    },
  },
  submitListButton: {
    display: "flex",
    justifyContent: "space-around",
    background: "#FFF",
    boxShadow: "0",
    paddingX: "28px",
    height: "56px",
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontSize: "1.2rem",
    textTransform: "none",
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem",
      marginLeft: 4,
    },
    "&:hover": {
      background: "#FFF",
      boxShadow: 3,
    },
  },
  downloadLink: {
    textDecoration: "none",
    "& .MuiButton-root": {
      color: "#101010",
      border: "1px solid #101010",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#FFF",
        boxShadow: 3,
      },
    },
  },
  exportLink: {
    textDecoration: "none",
  },
  importButton: {
    display: "flex",
    justifyContent: "space-around",
    background: "#FFF",
    boxShadow: "0",
    paddingX: "28px",
    height: "56px",
    border: "1px solid #101010",
    color: "#101010",
    fontSize: "1.2rem",
    textTransform: "none",
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem",
      marginLeft: 4,
    },
    "&:hover": {
      background: "#FFF",
      boxShadow: 3,
    },
  },
  tableHead: {
    height: "90px",
  },
  tableRow: {
    height: "90px",
  },
  tableColumnHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  bottomBorder: {
    borderBottom: "1px solid rgba(224,224,224,1)",
  },
  borderNone: {
    border: "none",
  },
  tableHeadTitle: {
    textAlign: "center !important",
    fontSize: "1.5rem",
    fontWeight: 700,
    "&:hover": {
      color: '#000'
    },
  },
  tableHeadMax: {
    maxWidth: "300px",
  },
  loadCell: {
    width: "30%",
  },
  tableCell: {
    fontSize: "1.3rem",
    //flex: 1,

    //news
    //width: '150px' ,
    textAlign: 'center'
  },
  tableCellDataGrid: {
    minHeight: '30px',
    lineHeight: '28px',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    bgcolor: '#0202',
    margin: 0,
    padding: '0 1px',
  },
  tableCellCertification: {
    margin: 0,
    padding: '0',
    display: 'flex',
    gap: 10,
    fontWeight: '700',
    fontSize: '1.3rem',
    lineHeight: '28px',
    justifyContent: 'space-around'
  },
  tableCellCompany: {
    fontSize: "1.5rem",
    flex: 1,
    "&:hover": {
      textDecoration: 'underline'
    },
  },
  qStatusBox: {
    "&::first-letter": {
      textTransform: "capitalize",
    },
    border: "1px solid #101010",
    width: "100%",
    height: "50px",
    fontSize: "1.5rem",
    borderRadius: "4px",
    textAlign: "center",
    padding: "7px 0",
  },
  moreButton: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    width: "100%",
    variant: "outlined",
    color: "black",
    textTransform: "none",
    fontSize: "1rem",
    textDecoration: "none",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  csvButton: {
    background: "#FFF",
    boxShadow: "none",
    width: "100%",
    height: "56px",
    border: "1px solid #101010",
    color: "#101010",
    fontSize: "1.2rem",
    textTransform: "none",
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem",
      marginLeft: 1,
    },
    "&:hover": {
      background: "#FFF",
      boxShadow: 3,
    },
  },
  btnStyleSm:{
    [theme.breakpoints.down('md')]: {
      height: '40px',
      fontSize: '1rem',
      '& span': {
        '& .MuiSvgIcon-root': {
          fontSize: '1.5rem',
        },
      },
      "& .MuiOutlinedInput-root": {
        height: '40px',
      },
    }
  },
  searchInput: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      position: "relative",
      "& .MuiInputAdornment-root": {
        color: "#101010",
      },
    },
  },
  dialog: {
    maxWidth: "sm",
    title: {
      fontSize: "2rem",
      fontWeight: "700",
      color: "#101010",
    },
    content: {
      fontSize: "1.5rem",
      color: "#101010",
    },
    dialogBox: {
      "& .MuiDialog-paper": {
        padding: "48px",
      },
    },
    cancelButton: "rgba(36, 35, 35, 0.5)",
    ctaButton: "#101010",
    showButton: "none",
  },
  wrapperBackHome: {
    textAlign: 'center',
    padding: '20px 0px',
  },
  tableCellLastModified: {
    fontSize: "1.25rem",
    flex: 1,
  },
  wrapperTable: {
    minHeight: '965px',
    width: '100%',
    backgroundColor: '#FFF',
  },
  tableGrid: {
    minHeight: '965px !important',
    borderRadius: '4px',
    width: '100%',
    fontSize: "1em",
    [theme.breakpoints.down('md')]: {
      minHeight: '1565px !important',
    },    
    //'&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '0px' },
    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
    //'&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '0px' },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    /* '& .MuiDataGrid-columnHeaders' : {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    }, */
    '& .MuiDataGrid-columnHeadersInner': {
      fontSize: "1.5rem",
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
      },
      '& .MuiDataGrid-columnHeader': {
        padding: '0',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: '700',
        paddingLeft: '12px',
        textAlign: "center !important",
        fontSize: "1.5rem",
        //minWidth: '150px',
        //maxWidth: '350px',
      },
    },
    '& .MuiDataGrid-row': {
      fontSize: "15px",
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
    '& .MuiDataGrid-cell': {
      //height: '150 !important',
      display: 'flex',
      alignItems: 'center',
      //lineHeight: '30px',
    },
    /* '& .MuiDataGrid-virtualScroller': {
      [theme.breakpoints.down('sm')]: {
        marginTop: '0 !important',
      },
      '::-webkit-scrollbar': {
        width: '10px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '::-webkit-scrollbar-thumb': {
        background: 'rgba(16, 16, 16, 0.5)',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    }, */
    '& 	.MuiDataGrid-checkboxInput': {
      '& .MuiSvgIcon-root': {
        fontSize: "1.5rem",
        [theme.breakpoints.down('md')]: {
          fontSize: '16px',
        },
      },
    },
  },
});

export const EmployeeDetailStyles = () => ({
  employeeDetails: {
    marginTop: "25px",
    width: '100%',
    "& h6": {
      fontSize: "1.125rem",
      fontWeight: 700,
      marginBottom: "30px",
    },
  },
  employeeDetailTitle:{
    display: { xl: "flex", md: "flex", sm: "flex", xs: "block" },
    gap: { xl: "40px", lg: "40px", md: "40px", sm: "40px", xs: "10px" },
  },
  employeeDetailTitleBox:{
    display: 'flex',
    gap: '10px',
    marginBottom: { xl: "0px", lg: "0px", md: "0px", sm: "0px", xs: "20px" },
  },
  employeeDetailTypographicBox:{
    margin: 0,
    lineHeight: '15px',
  },
  detailFields: {
    "& > div": {
      "& .MuiInputLabel-shrink": {
        padding: "0 8px",
        background: "#FFF",
        color: theme.palette.primary.main,
        fontSize: "20px",
      },
      //"& span": { display: "none" },
      "& .MuiFormHelperText-root": {
        background: "#FFF",
      },
    },
  },
  detailRows: {
    "& > div": {
      height: "55px",
      width: "23%",
      "& .MuiOutlinedInput-root": {
        height: "100%",
        "& .MuiOutlinedInput-input": {
          padding: "0 24px",
        },
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main,
            borderWidth: "2px",
          },
        },
      },
      "& .MuiInputLabel-root": {
        top: "-30px",
        left: "10px",
        transform: "translate(0px, 5px)",
      },
      "& .MuiInputLabel-shrink": {
        padding: "0 8px",
        background: "#FFF",
        color: theme.palette.primary.main,
        top: "-30px",
        left: "0px",
        transform: "translate(15px, 20px)",
      },
      "& span": { display: "none" },
      "& .MuiFormHelperText-root": {
        position: "absolute",
        bottom: "-10px",
        left: "10px",
        background: "#FFF",
        padding: "0 10px",
        fontSize: "0.875rem",
        margin: "0",
      },
    },
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px",
  },
  linkBox: {
    "& p": {
      fontSize: "1.125rem",
      marginRight: "16px",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  linkText: {
    width: "60%",
  },
  sendButton: {
    height: "50px",
    width: "15%",
    background: theme.palette.primary.main,
    color: "#FFF",
    fontSize: "1.125rem",
    "&:hover": {
      background: theme.palette.primary.main,
      boxShadow: 4,
    },
  },
  saveChangesBtn: {
    textTransform: "none",
    width: "60%",
    height: "58px",
    fontSize: "1.125rem",
    background: "#101010",
    color: "#FFF",
    margin: "48px 0",
    "&:hover": {
      background: "#101010",
      boxShadow: 6,
    },
  },
  select: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "16px 20px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #101010",
    },
  },
  selectError: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #d32f2f",
      "&:hover": {
        border: "1px solid #d32f2f",
      },
    },
  },
  dialog: {
    maxWidth: "sm",
    title: {
      fontSize: "2rem",
      fontWeight: "700",
      color: "#101010",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
    },
    content: {
      fontSize: "1.5rem",
      color: "#101010",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.125rem",
      },
    },
    dialogBox: {
      "& .MuiDialog-paper": {
        padding: "48px",
        [theme.breakpoints.down("sm")]: {
          padding: "24px",
        },
      },
    },
    cancelButton: "rgba(36, 35, 35, 0.5)",
    ctaButton: "#101010",
    showButton: "none",
  },
  downloadButton: {
    height: "50px",
    width: "15%",
    background: 'trasparent',
    color: "#000",
    border: '1px solid #C4C4C4',
    fontSize: "1.125rem",
    textTransform: 'none',
    "&:hover": {
      background: theme.palette.primary.main,
      boxShadow: 4,
      border: 'none',
      color: '#FFF'
    },
  },
});

export const QuestionnaireResultsStyles = () => ({
  mainBox: {
    margin: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& h1": {
      fontSize: "2.5rem",
      margin: "15px 0",
      fontWeight: 700,
      textAlign: "center",
    },
    "& h3": {
      fontSize: "1.3rem",
      marginBottom: "30px",
      textAlign: "center",
    },
  },
  subBox: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: "40px 5vw",
    position: "relative",
    width: { xl: "80%", md: "80%", sm: "80%", xs: "95%" },
    boxShadow: "0px 6px 8px 4px rgb(36 35 35 / 15%)",
    borderRadius: "4px",
  },
  buttonLinkGoBack: {
    marginRight: "auto",
    textDecoration: "none",
  },
  buttonLinkEdit: {
    marginLeft: "auto",
    textDecoration: "none",
  },
  qaBox: {
    paddingLeft: "1em",
    "& .MuiBox-root": {
      padding: "0.5em 1em",
    },
  },
});
