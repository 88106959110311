// @vendors
import React, { useContext } from 'react'
import { isEmpty as _isEmpty } from "lodash";
import sx from "mui-sx";
import { useDispatch, useSelector } from "react-redux";
import { TableSortLabel } from "@mui/material";
import { visuallyHidden } from '@mui/utils';

// @materialui
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Box
} from "../../components/shared/MaterialUI";

// @assets
import { EmployeeTableStyles } from '../../assets/css/employeeTable-style';

// @components
import { SearchEmployeeContext } from './SearchEmployees';
import EmployeeRow from '../Employer/components/EmployeeTable/components/EmployeeRow';
import TableToolbarSearch from './TableToolbarSearch';
import { TablePaginationActions } from '../Admin/components/custom/TablePaginationActions';

// @constants
import { types } from "../../redux/types/index.js";

const SearchEmployeeTable = () => {
  const styles = EmployeeTableStyles();
  const {
    rowsState,
    pages,
    search,
    setPages,
    getAllEmployesByCompany
  } = useContext(SearchEmployeeContext);
  const dispatch = useDispatch()
  const sortEmployee = useSelector((loadedState) => loadedState.sortEmployeeReducer);

  const handlePageChange = (event, nextPage) => {
    const page = nextPage === 0 ? 1 : nextPage === pages.page ? nextPage + 1 : nextPage
    setPages((prev) => ({
      ...prev,
      page: parseInt(page),
      size:pages.size
    }));
    getAllEmployesByCompany(page, parseInt(pages.size))
  };

  const handlePageSizeChange = (event) => {
    setPages((prev) => ({
      ...prev,
      page: 1,
      size: event.target.value
    }));
    getAllEmployesByCompany(1, event.target.value)
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const onRequestSort = (event, property) => {
    const isAsc = sortEmployee.sort === property && sortEmployee.direction === 'asc';
    sortDispatch(property, isAsc ? 'desc' : 'asc')
    getAllEmployesByCompany(1, parseInt(pages.size), search, property, isAsc ? 'desc' : 'asc')
  };
  
  const onRequestSortCertification = (property, option) => {
    sortDispatch(property, option)
    getAllEmployesByCompany(1, parseInt(pages.size), search, property, option)
  };

  const sortDispatch = (property, option) => {
    return dispatch({
        type: types.SORT_EMPLOYEE,
        sort: property,
        direction: option
      })
  }

  const sortTableCell = (label, nameToSearch) => {
    return (
      <TableSortLabel
        active={sortEmployee.sort === nameToSearch ? true : false}
        direction={sortEmployee.sort === nameToSearch ? sortEmployee.direction : 'asc'}
        onClick={createSortHandler(nameToSearch)}
        sx={{ ...styles.tableHeadTitle}}
      >
        {label}
          <Box component="div" sx={visuallyHidden}>
            {sortEmployee.direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Box>
      </TableSortLabel>
    )
  }

  return (
      <Paper elevation={4}>
        <TableToolbarSearch/>
        <TableContainer sx={styles.tableContainer}>
          <Table aria-label="collapsible table" stickyHeader>
            <TableHead>
              <TableRow sx={styles.tableRow}>
                <TableCell sx={styles.emptyTableCell} />

                <TableCell
                  sx={{ ...styles.tableHeadTitle, textAlign: 'left' }}
                  sortDirection={sortEmployee.sort === 'company' ? sortEmployee.direction : false}
                >
                  {sortTableCell('Company', 'company')}
                </TableCell>

                <TableCell
                  sx={{ ...styles.tableHeadTitle, textAlign: 'left' }}
                  sortDirection={sortEmployee.sort === 'name' ? sortEmployee.direction : false}
                >
                  {sortTableCell('Full name', 'name')}
                </TableCell>

                <TableCell 
                  sx={{ ...styles.tableHeadTitle, textAlign: 'left' }}
                  sortDirection={sortEmployee.sort === 'update' ? sortEmployee.direction : false}
                >
                  {sortTableCell('Last Modified Date', 'update')}
                </TableCell>

                <TableCell 
                  sx={sx(styles.tableHeadTitle, styles.tableColumnHide)}
                  sortDirection={sortEmployee.sort === 'status' ? sortEmployee.direction : false}
                >
                  {sortTableCell('Questionnaire Status', 'status')}
                </TableCell>

                <TableCell 
                  sx={sx(styles.tableHeadTitle, styles.tableColumnHide)}
                  sortDirection={sortEmployee.sort === 'days' ? sortEmployee.direction : false}
                >
                  {sortTableCell('Remaining Days', 'days')}
                </TableCell>

                <TableCell 
                  sx={sx(styles.tableHeadTitle, styles.tableColumnHide)}
                  sortDirection={sortEmployee.sort === 'groups' ? sortEmployee.direction : false}
                >
                  {sortTableCell('Target Group', 'groups')}
                </TableCell>

                <TableCell 
                  sx={sx(styles.tableHeadTitle, styles.tableColumnHide)}
                  sortDirection={sortEmployee.sort === 'wotc' ? sortEmployee.direction : false}
                >
                  {sortTableCell('WOTC eligibility', 'wotc')}
                </TableCell>

                <TableCell 
                  sx={sx(styles.tableHeadTitle, styles.tableColumnHide)}
                  sortDirection={sortEmployee.sort === 'submit' ? sortEmployee.direction : false}
                >
                  {sortTableCell('Ready to Submit', 'submit')}
                </TableCell>

                <TableCell
                  sx={sx(
                    styles.tableHeadTitle,
                    styles.tableHeadMax,
                    styles.tableColumnHide,
                    {
                      condition: _isEmpty(rowsState.rows),
                      sx: styles.loadCell,
                    }
                  )}
                >
                  State certification
                  {_isEmpty(rowsState.rows) ? null :
                    <div style={{display: 'flex', gap: 0, marginTop: '10px'}}>
                      <div>
                        <TableSortLabel
                          active={sortEmployee.sort === 'certification' && sortEmployee.direction === 'Yes' ? true : false}
                          direction={'asc'}
                          onClick={() => onRequestSortCertification('certification', 'Yes')}
                          sx={{ ...styles.tableHeadTitle}}
                        >
                           Yes
                        </TableSortLabel>
                      </div>
                      <div>
                        <TableSortLabel
                          active={sortEmployee.sort === 'certification' && sortEmployee.direction === 'No' ? true : false}
                          direction={'asc'}
                          onClick={() => onRequestSortCertification('certification', 'No')}
                          sx={{ ...styles.tableHeadTitle}}
                        >
                          No
                        </TableSortLabel>
                      </div>
                      <div>
                        <TableSortLabel
                          active={sortEmployee.sort === 'certification' && sortEmployee.direction === 'Pending' ? true : false}
                          direction={'asc'}
                          onClick={() => onRequestSortCertification('certification', 'Pending')}
                          sx={{ ...styles.tableHeadTitle}}
                        >
                          Pending
                        </TableSortLabel>
                      </div>
                    </div>
                  }
                </TableCell>
                              
                <TableCell 
                  sx={sx(
                    styles.tableHeadTitle,
                    styles.tableHeadMax,
                    styles.tableColumnHide,
                    {
                      condition: _isEmpty(rowsState.rows),
                      sx: styles.loadCell,
                    }
                  )}
                  sortDirection={sortEmployee.sort === 'ez' ? sortEmployee.direction : false}
                >
                  {sortTableCell('EZ', 'ez')}
                </TableCell>
                
                <TableCell 
                  sx={sx(
                    styles.tableHeadTitle,
                    styles.tableHeadMax,
                    styles.tableColumnHide,
                    {
                      condition: _isEmpty(rowsState.rows),
                      sx: styles.loadCell,
                    }
                  )}
                  sortDirection={sortEmployee.sort === 'rrc' ? sortEmployee.direction : false}
                >
                  {sortTableCell('RRC', 'rrc')}
                </TableCell>

                <TableCell 
                  sx={sx(styles.tableHeadTitle, styles.tableColumnHide)}
                  sortDirection={sortEmployee.sort === 'sy' ? sortEmployee.direction : false}
                >
                  {sortTableCell('Summer Youth', 'sy')}
                </TableCell>

                <TableCell
                  sx={sx(styles.tableHeadTitle, styles.tableColumnHide)}
                  sortDirection={sortEmployee.sort === 'statesent' ? sortEmployee.direction : false}
                >
                  {sortTableCell('State Sent', 'statesent')}
                </TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {React.Children.toArray(
                rowsState?.rows &&
                rowsState?.rows.map((row) => <EmployeeRow
                        row={row}
                        isSearch={true}
                        getAllEmployesByCompany={getAllEmployesByCompany}
                        search={search}
                        pages={pages}
                    />
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 20, 50, 100]}
          colSpan={3}
          count={rowsState.rowCount || 0}
          rowsPerPage={parseInt(rowsState.pageSize)}
          page={rowsState.page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={(e) => handlePageSizeChange(e)}
          ActionsComponent={() => TablePaginationActions(rowsState.rowCount, pages.page, pages.size, handlePageChange)}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
        />
      </Paper>
  );
}

export default SearchEmployeeTable